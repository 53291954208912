import AccountsExceptions from 'gen-thrift/accounts_Exceptions_types';
import UnauthenticatedUserSessionExceptions from 'gen-thrift/unauthenticated_user_session_Exceptions_types';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';
import { cookieManager, userSessionManager } from 'shared/lib/manager';

const djangoApiManager = new DjangoApiManager();
const runtimeExceptionMessage = 'handle RuntimeException';

const handleCustomErrors = (error : Error) => {
    /**
     * <bad design>
     *
     * We shouldn't be doing this, but until we have a TypeScript app for catching errors on every page, we'll let
     * error_catching.js show the error dialog
     */

    if (error instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
        cookieManager.deleteCookie('unauthenticatedUserSessionId');
        window.location.reload();
        return;
    }

    if ((error instanceof AccountsExceptions.UnknownSessionIdentifierException) || (error instanceof AccountsExceptions.UnknownActorException)) {
        userSessionManager.terminateSession();
        window.location.reload();
        return;
    }

    if (error.name === "AccountDoesNotHavePermissionException") {
        window.showGlobalErrorDialog('You do not have permission to perform this operation', 403);
        return;
    }

    if (error && (error.message === runtimeExceptionMessage)) { // Checking this to make sure we are not dealing with the same Error in a loop/
        return;
    }

    throw new Error(runtimeExceptionMessage); // throws it over to error_catching.js to handle it

    /**
     * </bad design>
     */
};

window.showGlobalErrorDialog = (
    optionalMessage: string | null,
    // @ts-ignore
    statusCode?: number = 500,  // could use some enum/type to express error type... unclear what paradigm we want
) => {
    window.handleAjaxError(statusCode, null, null, optionalMessage);
};

window.addEventListener('error', (event : ErrorEvent) => {
    // error occurs in script served from another domain
    if ((event.message === 'Script error.') || (event.message === 'Script error')) {
        // we are getting spammed by these errors, and they are probably originating from errors in third party tracking libraries
        // tslint:disable-next-line:no-console
        console.error('JS Error not logged!', event);
        return;

    // these are only thrown from in here with the intention of the error being handled by error_catching.js
    // } else if (event.message.indexOf(runtimeExceptionMessage) >= 0) {
    //     return;
    }

    // unclear which library is using ResizeObserver but it throws intermittant errors (not reproduceable). Can safely ignore.
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    if (event.message === 'ResizeObserver loop limit exceeded') {
        // tslint:disable-next-line:no-console
        console.error('ResizeObserver error not logged!', event);
        return;
    }

    setTimeout(() => handleCustomErrors(event.error), 0);

    if (window.navigator.onLine) {
        djangoApiManager.logJsError(
            event.message,
            event.filename + ':' + event.lineno + ':' + event.colno,
            event.error,
            false);
    }
});

export const handleUnhandledRejection = (event : Event) => {
    // typescript 2.7's strictFunctionTypes prevents us from typing event as PromiseRejectionEvent
    // https://stackoverflow.com/a/47171216/3100850
    const promiseRejectionEvent = event as PromiseRejectionEvent;

    // tslint:disable-next-line:no-console
    console.log('unhandledrejection was actually found');

    if (typeof (window as any).PromiseRejectionEvent === 'undefined' ||
        !(event instanceof (window as any).PromiseRejectionEvent)) {
        // we've seen browser extentions throwing this event with "custom events", missing the info we need to report on it
        // @see Honey https://chrome.google.com/webstore/detail/honey/bmnlcjabgnpnenekpadlanbbkooimhnj
        // tslint:disable-next-line:no-console
        console.error('Unrecognized `unhandledrejection` event', event);
        return;
    }

    if (event.isTrusted) {
        handleCustomErrors(promiseRejectionEvent.reason);
    }

    if (window.navigator.onLine) {
        djangoApiManager.logJsError(
            '(' + event.type + ') ' + promiseRejectionEvent.reason,
            null,
            promiseRejectionEvent.reason,
            false);
    }
};

window.addEventListener('unhandledrejection', handleUnhandledRejection);

window.addEventListener('offline', (event) => {
    // throw new NoInternetConnectionException('window offline event triggered');
});

// window.addEventListener('online', function(event) { console.log('online'); });
