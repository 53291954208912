import * as manager from 'shared/lib/manager';

// Lesman 1-31-2017
// Unfortunately these are not modules and cannot be loaded in the same way
// that the manager is loaded into a namespace because the thrift compiler bad
// Jeff 2017-02-08
// these are modules now, so maybe we should revisit this
import AccountsExceptions from 'gen-thrift/accounts_Exceptions_types';
import CorePrimitivesModel from 'gen-thrift/core_primitives_Model_types';
import DistributorExceptions from 'gen-thrift/distributor_Exceptions_types';
import LocationModel from 'gen-thrift/location_Model_types';
import ProductExceptions from 'gen-thrift/product_Exceptions_types';
import UnauthenticatedUserSessionExceptions from 'gen-thrift/unauthenticated_user_session_Exceptions_types';

import { StringValueSet } from 'api/Core/StringValueSet';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorRegionId } from 'api/Distributor/model/DistributorRegionId';
import { DistributorObjectToJSONSerializer } from 'api/Distributor/serializer/DistributorObjectToJSONSerializer';
import { DistributorDependentObjectSet } from 'api/Distributor/model/DistributorDependentObjectSet';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { LocationId } from 'api/Location/model/LocationId';
import { Scope } from 'api/Location/model/Scope';
import { BaseUnit } from 'api/Product/model/BaseUnit';
import { MassUnit } from 'api/Product/model/MassUnit';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserEmailAddressVerificationCredential } from 'api/UserAccount/model/UserEmailAddressVerificationCredential';
import { DjangoApiManager } from 'shared/api/DjangoApiManager';
import { getCurrencySymbol } from 'shared/models/Currency';
import { DateTime } from 'shared/models/DateTime';
import { Integer } from 'shared/models/Integer';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { decimalUtils } from 'shared/utils/decimalUtils';
import { Observer } from 'shared/utils/observer';

window.BevspotPlatformClient = {
    logJsError: (new DjangoApiManager()).logJsError,
    Observer,
    accounts: {
        managers: {
            userEmailAddressVerifier: manager.userEmailAddressVerifier,
            userAccountManager: manager.userAccountManager,
            userSessionManager: manager.userSessionManager
        },

        models: {
            UserEmailAddressVerificationCredential,
            UserAccountId
        },

        exceptions: {
            UnknownEmailAddressVerificationCredentialException: AccountsExceptions.UnknownEmailAddressVerificationCredentialException,
            ExpiredEmailAddressVerificationCredentialException: AccountsExceptions.ExpiredEmailAddressVerificationCredentialException,
            EmailAddressVerificationCredentialAlreadyUsedException: AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException,
            EmailAddressAlreadyAssociatedWithAnAccountException: AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException,
        },
    },
    corePrimitives: {
        models: {
            EmailAddress: CorePrimitivesModel.EmailAddress,
        },
    },
    AjaxUtils,
    core: {
        DateTime,
        StringValueSet,
        Integer,
        getCurrencySymbol,
    },
    distributor: {
        services: {
            DistributorServiceInstance: manager.DistributorServiceInstance,
            serializer: DistributorObjectToJSONSerializer,
        },
        models: {
            Scope,
            Distributor,
            DistributorId,
            DistributorRegionId,
        },
        exceptions: DistributorExceptions
    },
    location: {
        models: {
            ThriftLocationIdentifier: LocationModel.LocationIdentifier,
            LocationId
        },
        managers: {
            locationProductService: manager.locationProductService,
        }
    },
    product: {
        managers : {
            ProductDistributorServiceInstance: manager.ProductDistributorServiceInstance,
            productCostService: manager.productCostService,
            productService: manager.productService,
        },
        serializers: {
            productJSONToObjectSerializer: manager.productJSONToObjectSerializer
        },
        models: {
            ProductId,
            MassUnit,
            PackagingUnit,
            BaseUnit,
            VolumeUnit,
            QuantityInUnit,
            OldPackaging,
            ProductCost,
            PackagingId,
        },
        exceptions : ProductExceptions
    },
    unauthenticatedUserSessions: {
        managers: {
            unauthenticatedUserSessionManager: manager.unauthenticatedUserSessionManager,
        },

        exceptions: {
            UnknownUnauthenticatedUserSessionIdException: UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException,
        },
    },
    inventory: {
        models: {
            InventoryCountId,
        },
    },
    oldPackagingUtils,
    PackagingUtils,
    productCostUtils,
    decimalUtils,
    CategoryUtils,
};
